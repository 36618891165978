import React from 'react';
import ReactDOM from 'react-dom';
import {Alert} from 'react-bootstrap';
import styles from './ErrorMessage.module.scss';

////////////////////////////////
// Warning Message
////////////////////////////////

type ErrorMessageProps =
  {
  header?: string,
  onClose?: () => void;
  };

function ErrorMessage ({header = "Error!", onClose = () => {}, children = null}: ErrorMessageProps & React.PropsWithChildren)
  {
  return ReactDOM.createPortal(<div className={styles['errorMessageOverlay']}>
    <Alert variant="danger" dismissible onClose={onClose} className={styles['errorMessage']}>
      <Alert.Heading>{header}</Alert.Heading>
      {children}
      </Alert>
    </div>, document.body);
  };

export default ErrorMessage;
