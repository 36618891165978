import React from 'react';
import ReactDOM from 'react-dom';
import {Alert, Button, ButtonToolbar} from 'react-bootstrap';
import {useConfirmDialog, ConfirmDialogStore} from '../../atoms/Modals/ConfirmDialog.hooks';
import styles from './ConfirmDialog.module.scss';

////////////////////////////////
// Confirm Dialog
////////////////////////////////

type ConfirmDialogProps =
  {
  header?: string;
  message?: string;
  dialog: ConfirmDialogStore
  }

const cssFlexRow = "d-flex flex-row justify-content-center align-items-center gap-2 m-0 px-0 py-2";
function ConfirmDialog ({ header, message, dialog }: ConfirmDialogProps)
  {
  return ((dialog.activated)
    ? ReactDOM.createPortal(<div className={styles['confirmDialogOverlay']}>
        <Alert variant="light" dismissible onClose={dialog.eventCancel} className={styles['confirmDialog']}>
          {header ? <Alert.Heading>{header}</Alert.Heading> : null}
          {message ? <div className="m-0 px-0 py-2 border-0 fs-6">{message}</div> : null}
          <ButtonToolbar className={cssFlexRow}>
            <Button variant="outline-dark" onClick={dialog.eventConfirm}>OK</Button>
            <Button variant="outline-dark" onClick={dialog.eventCancel}>Cancel</Button>
            </ButtonToolbar>
          </Alert>
        </div>, document.body)
    : null);
  };

export default ConfirmDialog;
