/*
DOCUMENT OBJECT MODEL
*/
// encapsulation


export const DOM = {
  root: document.getElementById('root') ?? document.body,
  element: document.documentElement,
};
