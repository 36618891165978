/* NODE PACKAGES */
import React from 'react';
import {Form, FormGroup} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {useAuthToken, TokenStore} from 'components/molecules/AuthModal/AuthModal.hooks';

/////////////////////////////////////////
// MODAL: AUTHENTICATION
/////////////////////////////////////////

function AuthModal (props: {token: TokenStore})
  {
  const [show, setShow] = React.useState<boolean>(!props.token.authorized);
  const inputRef: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);

  React.useEffect(() =>
    {
    (!props.token.authorized) ? setShow(true) : setTimeout(() => setShow(false), 1000);
    }, [props.token.authorized]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) =>
    {
    if (e.key === 'Enter') props.token.setPassword(inputRef.current?.value || '');
    }

  return (<Modal show={show} onHide={() => {}} autoFocus={false} size='sm' centered={true} backdrop='static' keyboard={false} restoreFocus={true} enforceFocus={true} animation={true}>
    <Modal.Body className="text-center m-0 p-3">
      <FormGroup controlId="form-password">
        <Form.Label className={`fs-1 ${props.token.authorized ? 'text-success' : 'text-danger'}`}><i className="bi bi-database-lock"></i></Form.Label>
        <Form.Control
          type="password"
          ref={inputRef}
          autoComplete="new-password"
          defaultValue={props.token.password}
          placeholder="Enter password"
          isValid={props.token.authorized}
          isInvalid={!props.token.authorized}
          onKeyUp={handleKeyPress}
          />
        <Form.Control.Feedback type="valid">Token Authorized!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">Type password & press ENTER key</Form.Control.Feedback>
        </FormGroup>
      </Modal.Body>
    </Modal>);
  };

export default AuthModal;



// Example Usage:
//
// import React, { useState } from 'react';
// import AuthModal from './AuthModal';
//
// const App: React.FC = () =>
//   {
//   const [authenticated, setAuthenticated] = useState<boolean>(false);
//   const handleAuthSuccess = () => setAuthenticated(true); // Callback function to update authentication state
//
//   return (
//     <div className="app">
//       <h1>Your App</h1>
//       {/* Other components and content of your app */}
//
//       {/* Use AuthModal component */}
//       {!authenticated && <AuthModal onAuthSuccess={handleAuthSuccess} />}
//
//       {/* Render the rest of your app content */}
//       </div>
//     );
//   };
//
// export default App;
