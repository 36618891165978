import React from 'react';
import {DataDictionaryStore} from 'common/hooks';

/////////////////////////////////////////
// TYPE
/////////////////////////////////////////

export type ApplicationContext =
  {
  dictionary: DataDictionaryStore;
  unsavedChanges: React.MutableRefObject<boolean>;
  };

/////////////////////////////////////////
// CONTEXT
/////////////////////////////////////////

export const ApplicationContext: React.Context<ApplicationContext | undefined> = React.createContext<ApplicationContext | undefined>(undefined);

export default function useApplicationContext(): ApplicationContext
  {
  const context: ApplicationContext | undefined = React.useContext(ApplicationContext);
  if (!context || !context.dictionary || !context.unsavedChanges) throw new Error('Error: Critical data missing from application context.');
  return context;
  }
