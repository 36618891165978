import React from 'react';
import AuthToken from "components/molecules/AuthModal/AuthToken";
import { AxiosError, AxiosResponse } from 'axios';

export type TokenStore =
  {
  authorized: boolean;
  password: string;
  setPassword: (password: string) => void;
  setAuthorized: (authorized: boolean) => void;
  }

export function useAuthToken(): TokenStore
  {
  const [password, setPassword] = React.useState<string>(AuthToken.use() || '');
  const [authorized, setAuthorized] = React.useState<boolean>(false);

  React.useEffect(() =>
    {
    const response = (d:AxiosResponse) =>
      {
      AuthToken.store(password);
      setAuthorized(true);
      setPassword(password);
      }

    const error = (e:AxiosError) =>
      {
      AuthToken.clear();
      setAuthorized(false);
      setPassword('');
      }

    AuthToken.client.request({url: '/api/auth_check', headers: {'Authorization': `Bearer ${password}`}})
      .then(response)
      .catch(error)
      .finally(() => console.log('/api/auth_check'));
    },[password]);

  return ({
    authorized,
    password,
    setAuthorized,
    setPassword,
    });
  }
