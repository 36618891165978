import React from 'react';

type ErrorBoundaryProps =
  {
  fallback: React.ReactNode;
  }

type ErrorBoundaryState =
  {
  hasError: boolean;
  }

class ErrorBoundary extends React.Component<ErrorBoundaryProps & React.PropsWithChildren, ErrorBoundaryState>
  {
  constructor(props: ErrorBoundaryProps)
    {
    super(props);
    this.state = { hasError: false };
    }

  // Update state so the next render will show the fallback UI.
  static getDerivedStateFromError(error: any)
    {
    return { hasError: true };
    }

  // Replace or implement this function if you want to log errors
  componentDidCatch(error: any, info: any)
    {
    console.error('ErrorBoundary caught an error', error, info);
    }

  render()
    {
    return (this.state.hasError) ? this.props.fallback : this.props.children;
    }
  }

export default ErrorBoundary;
