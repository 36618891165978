import React from 'react';

export type ConfirmDialogStore =
  {
  activated: boolean;
  showConfirmDialog: () => Promise<boolean>;
  eventConfirm: () => void;
  eventCancel: () => void;
  };

type ConfirmDialogProps =
  {
  }

export function useConfirmDialog (): ConfirmDialogStore
  {
  const [activated, setActivated] = React.useState<boolean>(false);
  const [confirmCallback, setConfirmCallback] = React.useState<(() => void) | null>(null);
  const [cancelCallback, setCancelCallback] = React.useState<(() => void) | null>(null);

  const showConfirmDialog = (): Promise<boolean> =>
    {
    return new Promise((resolve) =>
      {
      setConfirmCallback(() => () => resolve(true));
      setCancelCallback(() => () => resolve(false));
      setActivated(true);
      });
    };

  const eventConfirm = () =>
    {
    setActivated(false);
    confirmCallback && confirmCallback();
    }

  const eventCancel = () =>
    {
    setActivated(false);
    cancelCallback && cancelCallback();
    }

  return ({
    activated,
    showConfirmDialog,
    eventConfirm,
    eventCancel,
    });
  }

