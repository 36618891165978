/* NODE PACKAGES */
import React from 'react';
import ReactDOM from 'react-dom/client';

/* CUSTOM COMPONENTS */
import App from 'App';
import reportWebVitals from 'common/utility/reportWebVitals';
import { DOM } from "common/utility/DOM";
//import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
//import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import './stylesheet.scss';

if (DOM.element)
  {
  DOM.element?.setAttribute('data-bs-theme', 'light');
  }
else
  {
  console.error("DOM.element is not defined or is null.");
  }

if (DOM.root)
  {
  ReactDOM.createRoot(DOM.root).render(<React.StrictMode><App /></React.StrictMode>);
  }
else
  {
  console.error("DOM.root is not defined or is null.");
  }

reportWebVitals(console.log); // Optional, logs web vitals to the console
