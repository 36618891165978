/* Node Packages */
import React from 'react';
import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios';
import AuthToken from "components/molecules/AuthModal/AuthToken";


//////////////////////////////////////////
// RE-USABLE METHOD: requestAPI
//////////////////////////////////////////

export const axiosRequest = async <T = any, D = any>(config: AxiosRequestConfig<D>): Promise<T | null> =>
  {
  let trace: string = `${config.method} ${config.url}`;

  const eventResponse = (response: AxiosResponse<T>) =>
    {
    trace = `${config.method} ${config.url} » ${response.status}: ${response.statusText} = {${Object.keys(response?.data ?? []) as Array<keyof T>}}`;
    return Promise.resolve(response?.data ?? null);
    }

  function eventError(error: AxiosError<T>)
    {
    if (error.response)
      {
      if (error.response.status === 401) AuthToken.clear();
      trace = `${error.response.config.method} ${error.response.config.url} » ${error.response.statusText}`;
      }
    else if (error.request)
      {
      trace = `${config.method} ${config.url} » ${error.request}`;
      }
    else
      {
      trace = `${config.method} ${config.url} » ${error.message ?? error.toJSON()}`;
      }

    return Promise.resolve(null);
    }

  const eventTrace = () => console.log(trace);

  return await AuthToken.client.request<T>(config).then(eventResponse).catch(eventError).finally(eventTrace);
  }



//////////////////////////////////////////
// RE-USABLE CUSTOM HOOK: useAxios
//////////////////////////////////////////

function useAxios<T = any, D = any>(config?:AxiosRequestConfig<D>): readonly [T | null, boolean, boolean, (config:AxiosRequestConfig<D>) => void]
  {
  const [response , setResponse] = React.useState<T | null>(null);
  const [error    , setError   ] = React.useState<boolean>(false);
  const [loading  , setLoading ] = React.useState<boolean>(false);
  const [trace    , setTrace   ] = React.useState<string>(`${config?.method} ${config?.url}`);

  React.useEffect(() =>
    {
    if (config)
      {
      setLoading(true);
      XMLHttpRequest(config);
      }
    else
      {
      setResponse(null);
      setTrace(`config: ---`);
      }
    }, [config?.method, config?.url, config?.data]);

  const eventResponse = (res: AxiosResponse<T>) =>
    {
    setResponse(res?.data ?? null);
    setTrace(`${res.config.method} ${res.config.url} » ${res.status}: ${res.statusText} = {${Object.keys(res) as Array<keyof typeof res.data>}}`)
    }

  function eventError (error:AxiosError<T>)
    {
    setError(true);
    setResponse(null);
    if (error.response)
      {
      if (error.response.status === 401) AuthToken.clear();
      setTrace(`${error.response.config.method} ${error.response.config.url} » ${error.response.statusText}`); // The request was made and the server responded with a status code that falls out of the range of 2xx
      }
    else if (error.request)
      {
      setTrace(`${config?.method} ${config?.url} » ${error.request}`); // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      }
    else
      {
      setTrace(`${config?.method} ${config?.url} » ${error.message}`); // Something happened in setting up the request that triggered an Error
      }
    }

  const eventExitRequest = () =>
    {
    setLoading(false);
    (error) ? console.error(trace) : console.info(trace);
    }

  const XMLHttpRequest = (request:AxiosRequestConfig<D>) =>
    {
    AuthToken.client.request<T>(request).then(eventResponse).catch(eventError).finally(eventExitRequest);
    }

  return [response, error, loading, XMLHttpRequest] as const;
  };

export default useAxios;

