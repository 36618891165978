/* NODE PACKAGES */
import React from 'react';
/* HOOKS */
import {useDictionaryStore, DataDictionaryStore} from 'common/hooks';
import {ApplicationContext} from 'common/hooks/useApplicationContext';
import {useAuthToken, TokenStore} from 'components/molecules/AuthModal/AuthModal.hooks';
import AuthModal from 'components/molecules/AuthModal/AuthModal';
import {useConfirmDialog, ConfirmDialogStore} from 'components/atoms/Modals/ConfirmDialog.hooks';
import ConfirmDialog from 'components/atoms/Modals/ConfirmDialog';
import ErrorMessage from 'components/atoms/Modals/ErrorMessage';
import ErrorBoundary from 'components/atoms/Errors/ErrorBoundary';
import NavigationBar from 'components/atoms/Templates/NavigationBar';
import LoadingSpinner from 'components/atoms/Spinners/LoadingSpinner';
/* Pages: uses Lazy Loading */
const PolicyEditor = React.lazy(() => import('pages/Policies/PolicyEditor'));
const RegistrationEditor = React.lazy(() => import('pages/Registrations/RegistrationEditor'));
const RegistrationEditorOld = React.lazy(() => import('pages/Registrations/RegistrationEditorOld'));
const RequestForm = React.lazy(() => import('pages/Requests/RequestForm'));
const TemplateEditor = React.lazy(() => import('pages/Requests/TemplateEditor'));
const RequesterEditor = React.lazy(() => import('pages/Registrars/RequesterEditor'));
const RequesterGroupEditor = React.lazy(() => import('pages/Registrars/RequesterGroupEditor'));
const RegistrarEditor = React.lazy(() => import('pages/Registrars/RegistrarEditor'));
const RegistrarGroupEditor = React.lazy(() => import('pages/Registrars/RegistrarGroupEditor'));
const RegistryListEditor = React.lazy(() => import('pages/Registries/RegistryEditor'));
const Registrations = React.lazy(() => import('pages/Registrations'));
const RegistrarGroupMembers = React.lazy(() => import('pages/Registrars'));
const Templates = React.lazy(() => import('pages/Requests'));
const Registries = React.lazy(() => import('pages/Registries'));
const Policies = React.lazy(() => import('pages/Policies'));
const Dashboard = React.lazy(() => import('pages'));

////////////////////////////////
// TYPE DEFINITIONS
////////////////////////////////

export enum HashMode
  {
  policy = "/#/policy/",
  registration = "/#/registration/",
  registration_alpha = "/#/registration_alpha/",
  request_template = "/#/request_template/",
  request_execution = "/#/request_execution/",
  requester_group = "/#/requester_group/",
  requester = "/#/requester/",
  registrar_group = "/#/registrar_group/",
  registrar = "/#/registrar/",
  registry = "/#/registry/",
  registrations = "/#/registrations/",
  registrars = "/#/registrars/",
  registries = "/#/registries/",
  requests = "/#/requests/",
  policies = "/#/policies/",
  default = "/#/",
  }

// Represents the different modes of the application, based on the current URL hash. Each mode has an associated ID, which is extracted from the URL.
type AppMode =
  | { mode: HashMode.policy; id: number; }
  | { mode: HashMode.registration | HashMode.registration_alpha; id: number; }
  | { mode: HashMode.request_template; id: number;}
  | { mode: HashMode.request_execution; id: number;}
  | { mode: HashMode.requester_group; id: number; }
  | { mode: HashMode.requester; id: number; }
  | { mode: HashMode.registrar_group; id: number; }
  | { mode: HashMode.registrar; id: number; }
  | { mode: HashMode.registry; id: number}
  | { mode: HashMode.registrations; }
  | { mode: HashMode.registrars;}
  | { mode: HashMode.registries;}
  | { mode: HashMode.requests;}
  | { mode: HashMode.policies;}
  | { mode: HashMode.default; };

////////////////////////////////
// MAP HASH MODES TO APP MODES
////////////////////////////////

const regexMappings: Array<[RegExp, (match: RegExpMatchArray) => AppMode]> =
  [
  [/(\/policy\/(\d+))$/, (match: RegExpMatchArray) => ({ mode: HashMode.policy, id: parseInt(match[2], 10) }),],
  [/(\/registration_alpha\/(\d+))$/, (match: RegExpMatchArray) => ({ mode: HashMode.registration_alpha, id: parseInt(match[2], 10) })],
  [/(\/registration\/(\d+))$/, (match: RegExpMatchArray) => ({mode: HashMode.registration, id: parseInt(match[2], 10),}),],
  [/(\/request_template\/(\d+))$/, (match: RegExpMatchArray) => ({mode: HashMode.request_template, id: parseInt(match[2], 10),}),],
  [/(\/request_execution\/(\d+))$/, (match: RegExpMatchArray) => ({mode: HashMode.request_execution, id: parseInt(match[2], 10),}),],
  [/(\/requester_group\/(\d+))$/, (match: RegExpMatchArray) => ({ mode: HashMode.requester_group, id: parseInt(match[2], 10) }),],
  [/(\/requester\/(\d+))$/, (match: RegExpMatchArray) => ({ mode: HashMode.requester, id: parseInt(match[2], 10) }),],
  [/(\/registrar_group\/(\d+))$/, (match: RegExpMatchArray) => ({ mode: HashMode.registrar_group, id: parseInt(match[2], 10) }),],
  [/(\/registrar\/(\d+))$/, (match: RegExpMatchArray) => ({ mode: HashMode.registrar, id: parseInt(match[2], 10) }),],
  [/(\/registry\/(\d+))$/, (match: RegExpMatchArray) => ({ mode: HashMode.registry, id: parseInt(match[2], 10) }),],
  [/(\/registrations\/)$/, () => ({ mode: HashMode.registrations })],
  [/(\/registrars\/)$/, () => ({ mode: HashMode.registrars })],
  [/(\/registries\/)$/, () => ({ mode: HashMode.registries })],
  [/(\/requests\/)$/, () => ({ mode: HashMode.requests })],
  [/(\/policies\/)$/, () => ({ mode: HashMode.policies })],
  ];

////////////////////////////////
// HELPER: getAppModeFromHash
////////////////////////////////

const getAppModeFromHash = (): AppMode =>
  {
  const hash = window.location.hash;
  for (const [regex, modeFn] of regexMappings)
    {
    const match = hash.match(regex);
    if (match) return modeFn(match);
    }
  return {mode: HashMode.default};
  };

////////////////////////////////
// MAIN ENTRY POINT
////////////////////////////////

function App ()
  {
  const [mode, setMode] = React.useState<AppMode>({mode: HashMode.default});
  const hasUnsavedChangesRef: React.MutableRefObject<boolean> = React.useRef<boolean>(false);
  const unsavedChangesDialog: ConfirmDialogStore = useConfirmDialog();
  const authToken: TokenStore = useAuthToken();
  const dictionary: DataDictionaryStore = useDictionaryStore({authorized: authToken.authorized});

  const onHashChange = React.useCallback(() =>
    {
    const update = getAppModeFromHash();
    //if ((update.mode !== mode.mode) || Object.keys(update).some(key => (update as any)[key] !== (mode as any)[key])) setMode(update);
    if (update.mode === HashMode.default || (update.mode !== mode.mode) || Object.keys(update).some(key => (update as any)[key] !== (mode as any)[key])) setMode(update);
    }, [mode]);

  // Synchronize app mode with current hash when the component is mounted
  React.useEffect(() =>
    {
    setMode(getAppModeFromHash());
    }, []);

  // Synchronize hash with app mode when the hash changes
  React.useEffect(() =>
    {
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
    }, []);

  // Synchronize app mode with current hash when the component is mounted
  React.useEffect(() =>
    {
    hasUnsavedChangesRef.current = false;
    }, [mode]);

  const handleHashChange = async (event: HashChangeEvent) =>
    {
    // if the new url is the same as the current url, then do nothing
    if (hasUnsavedChangesRef.current && window.location.href === event.oldURL) return;
    // Otherwise, the new url is different than the current url, so use conditional logic for applying hash change.
    // If there are no unsaved changes or the user confirms they want to proceed (i.e. exit app mode without saving changes), then update hash mode.
    if (!hasUnsavedChangesRef.current || await unsavedChangesDialog.showConfirmDialog())
      {
      hasUnsavedChangesRef.current = false;
      onHashChange();
      }
    // If there are unsaved changes and the user cancels, the URL is reverted to the previous state.
    else
      {
      event.preventDefault();
      window.history.replaceState(null, '', `#${event.oldURL?.split('#')[1] ?? ''}`); // window.history.pushState(null, '', event.oldURL?.split('#')[1] ?? '');
      return;
      }
    };

  const renderPage = () =>
    {
    switch (mode.mode)
      {
      case HashMode.policy: return <PolicyEditor dataDictionary={dictionary.data} selectedID={mode.id} />;
      case HashMode.registration: return <RegistrationEditor selectedID={mode.id} />;
      case HashMode.registration_alpha: return <RegistrationEditorOld selectedID={mode.id} />;
      case HashMode.request_execution: return <RequestForm selectedID={mode.id} />;
      case HashMode.request_template: return <TemplateEditor selectedID={mode.id} />;
      case HashMode.requester: return <RequesterEditor selectedID={mode.id} />;
      case HashMode.requester_group: return <RequesterGroupEditor selectedID={mode.id} />;
      case HashMode.registrar: return <RegistrarEditor selectedID={mode.id} />;
      case HashMode.registrar_group: return <RegistrarGroupEditor selectedID={mode.id} />;
      case HashMode.registry: return <RegistryListEditor selectedID={mode.id} />;
      case HashMode.registrations: return <Registrations />;
      case HashMode.registrars: return <RegistrarGroupMembers />;
      case HashMode.requests: return <Templates />;
      case HashMode.registries: return <Registries />;
      case HashMode.policies: return <Policies />;
      case HashMode.default: return <Dashboard />;
      default: return <LoadingSpinner />;
      }
    }

  // password dialog only displays if a valid password is absent from local storage
  return (<ApplicationContext.Provider value={{ dictionary: dictionary, unsavedChanges: hasUnsavedChangesRef }}>
    <NavigationBar hashMode={mode.mode} />
    <ErrorBoundary fallback={<ErrorMessage>Something went wrong while loading the page. Please try refreshing or come back later. If the issue persists, contact support.</ErrorMessage>}>
      <React.Suspense fallback={<LoadingSpinner />}>{renderPage()}</React.Suspense>
      </ErrorBoundary>
    <AuthModal token={authToken} />
    <ConfirmDialog header="Unsaved changes!" message="Are you sure you want to exit before saving changes?" dialog={unsavedChangesDialog} />
    </ApplicationContext.Provider>);
  }

export default App;

/* Developer Notes:

This code defines the main component of a React application called "App". The purpose of this component is to manage the application's mode and data dictionary based on the current URL hash and to handle password authentication.

The code takes no direct input, but it relies on the current URL hash to determine the application mode. The output it produces is the rendered user interface of the application, which is determined by the application mode and the data dictionary.

To achieve its purpose, the code follows this logic and algorithm:

 * It defines an array of regular expressions and corresponding functions to map different URL hash patterns to application modes.
 * It defines a function getAppModeFromHash that uses the regular expression mappings to determine the application mode based on the current URL hash.
 * It defines the main App component as a functional component using React hooks.
 * Inside the App component, it initializes state variables to store the application mode, data dictionary, and loading state.
 * It checks if the password is stored in the browser's local storage. If not, it renders a modal prompting the user to enter the password.
 * If the password is stored, it sets up an effect hook to update the application mode whenever the URL hash changes. This effect listens for the hashchange event on the window and calls the handleHashChange function to update the application mode accordingly.
 * It sets up another effect hook to initialize the application mode based on the current URL hash when the component mounts.
 * The handleHashChange function compares the new mode derived from the URL hash with the current mode. If the modes are different, it updates the state with the new mode. If the modes are the same but some properties have changed, it also updates the state with the new mode.
 * The code does not perform any complex data transformations or logic flows beyond determining the application mode based on the URL hash and managing the state accordingly.

It's important to note that this code assumes the existence of certain functions and variables, such as storePassword, getPassword, ReactModal, and _ (likely from a utility library like Lodash), which are not shown in the provided code snippet.

*/
